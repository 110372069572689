<template>
  <div class="content_body ProductInventoryOutbound">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" :model="searchForm" @keyup.enter.native="searchDataList">
            <el-form-item v-if="EntityList.length>1" label="仓库/门店">
              <el-select v-model="searchForm.EntityID" clearable filterable placeholder="请选择仓库/门店" :default-first-option="true" @change="searchDataList">
                <el-option v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="产品">
              <el-input v-model="searchForm.ProductName" placeholder="请输入产品名称、别名" clearable @keyup.enter.native="searchDataList" @clear="searchDataList"></el-input>
            </el-form-item>

            <el-form-item label="出库类型">
              <el-select v-model="searchForm.OutType" clearable filterable placeholder="请选择出库类型" :default-first-option="true" @change="searchDataList" @clear="searchDataList">
                <el-option v-for="item in OutType" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="制单日期">
              <el-date-picker v-model="searchForm.DateTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" clearable @change="searchDataList"></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="searchDataList" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="2" class="text_right">
          <el-button type="primary" @click="addOutbound" size="small" v-prevent-click>新建出库</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="data_list" tooltip-effect="light">
      <el-table-column prop="ID" label="单据号"></el-table-column>
      <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
      <el-table-column prop="InventoryType" label="出库类型"></el-table-column>
      <el-table-column prop="CreatedOn" label="制单日期"></el-table-column>
      <el-table-column prop="CreatedByName" label="操作人"></el-table-column>
      <el-table-column prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="80px">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="showOutboundInfo(scope.row)" v-prevent-click>详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="ProductInventoryOutboundCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>

    <!-- 新建产品出库 -->
    <el-dialog custom-class="ProductOutboundDialogClass" title="新建出库" :visible.sync="ProductOutboundDialogVisible" width="1000px" @close="closeAddProductOutbound">
      <div class="tip marbm_10" style="margin-top:0">基本信息</div>
      <el-form class="ProductOutboundDialogInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="OutboundFrom" :rules="OutboundFromRules" ref="ProductOutboundFromRef">
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库/门店：" prop="EntityID">
              <el-select size="small" value-key="ID" v-model="OutboundFrom.EntityName" filterable placeholder="请选择仓库" @change="handleSelectEntity ">
                <el-option value-key="ID" v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出库时间：" prop="OutDate">
              <el-date-picker v-model="OutboundFrom.OutDate" type="datetime" placeholder="选择出库日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="OutboundFrom.default_time">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="martp_10">
          <el-col :span="12">
            <el-form-item label="出库类型：" prop="OutType">
              <el-select v-model="OutboundFrom.OutType" placeholder="请选择出库类型">
                <el-option v-for="item in newOutType" :key="item.ID" :label="item.Name" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注信息：">
              <el-input type="textarea" style="width:300px" :autosize="{ minRows: 1, maxRows: 3 }" v-model="OutboundFrom.Remark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip martp_10">产品明细</div>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" size="small" @click="addProducts">添加产品</el-button>
            <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
          </el-col>
        </el-row>

        <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="400px" :data="OutboundFrom.Detail" @selection-change="removeHandleChangeSelectProduct">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.ProductID'" :rules="OutboundFromRules.ProductID">
                <el-select v-model="scope.row.ProductID" size="small" filterable remote reserve-keyword v-loadmore="loadMoreProduct" placeholder="请选择产品" :remote-method="searchProductListMethod" :loading="productLoading" @change="(val)=>handleSelectProduct(val,scope.row)" @focus="selectFocus" @clear="clearSelectProduct(scope.row)" :default-first-option="true" popper-class="ProductInventoryOutbound_custom_popper_class">
                  <el-option v-for="item in ProductList" :key="item.ID" :label="item.ProductName" :value="item.ID" :disabled="item.IsLock">
                    <div class="dis_flex flex_dir_column pad_5_0">
                      <div>
                        <span>{{ item.ProductName }}</span>
                        <span class="color_gray marlt_5 font_12" v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLock" size="mini"  type="warning">{{'盘点锁定'}}</el-tag>
                      </div>
                      <div :class="item.ID == scope.row.ID?'font_12 dis_flex  flex_x_between' : 'color_gray font_12 dis_flex flex_x_between'">
                        <span class="">{{ item.PCategoryName }}</span>
                        <span class="marlt_5 " v-if="item.Specification">【{{ item.Specification }}】</span>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>

          </el-table-column>
          <el-table-column label="产品规格" prop="Specification">
            <template slot-scope="scope">
              {{scope.row.Specification}}
            </template>
          </el-table-column>
          <el-table-column label="实物库存" prop="StockQuantity">
            <template slot-scope="scope">
              {{scope.row.StockQuantity}} {{scope.row.miniUnitName}}
            </template>
          </el-table-column>
          <el-table-column label="出库单位" prop="UnitName">

            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.UnitName'" :rules="OutboundFromRules.UnitID">
                <el-select value-key="UnitID" v-model="scope.row.UnitName" size="small" filterable placeholder="请选择单位" @change="(val)=>handleSelectProductUnit(val,scope.row)" :default-first-option="true">
                  <el-option v-for="item in scope.row.Units" :key="item.UnitID" :label="item.UnitName" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="出库数量">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.Quantity'" :rules="OutboundFromRules.Quantity">
                <el-input v-model="scope.row.Quantity" size="small" placeholder="请输入出库数量" @input="changeOutboundQuantity(scope.row)" validate-event v-enter-number2 v-enterInt min="0" type="number"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="最小包装数量">
            <template slot-scope="scope" v-if="scope.row.Quantity">
              {{scope.row.Quantity * scope.row.miniAmount}} {{scope.row.miniUnitName}}
            </template>
          </el-table-column>
        </el-table>

      </el-form>

      <div slot="footer">
        <el-button @click="ProductOutboundDialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="saveProductOutboundLoadingClick" :loading="OutboundLoading" size="small" v-prevent-click>确认出库</el-button>
      </div>
    </el-dialog>

    <!-- 详情-->
    <el-dialog custom-class="ProductOutboundDialogClass" title="产品出库详情" :visible.sync="OutboundInfoDialogVisible" width="1000px">
      <div class="tip marbm_10" style="margin-top:0">产品出库信息</div>
      <el-form class="ProductOutboundDialogInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="OutboundInfo">
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请人：">{{OutboundInfo.CreatedByName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期：" prop="OutDate">{{OutboundInfo.CreatedOn}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库日期：" prop="OutDate">{{OutboundInfo.OutDate}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="出库仓库：">{{OutboundInfo.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库类型：">{{OutboundInfo.InventoryType}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注：">{{OutboundInfo.Remark}}</el-form-item>
          </el-col>
        </el-row>
        <div class="tip marbm_10 martp_10">产品明细</div>
        <el-table size="small" max-height="400px" :data="OutboundInfo.Detail" @selection-change="removeHandleChangeSelectProduct">
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="font_12 color_999">({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.PackageName" class="font_12 color_999">所属套餐： {{scope.row.PackageName}}</div>
              <div v-if="scope.row.Specification" class="font_12 color_999">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column label="产品分类" prop="PCategoryName">
          </el-table-column>
          <el-table-column prop="Quantity" label="出库数量">
            <template slot-scope="scope">
              <div>{{scope.row.Quantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.MinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
        </el-table>

      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/PSI/Inventory/inventoryProductOutbound";
import APIStorage from "@/api/PSI/Purchase/storage";
import APIInquire from "@/api/PSI/Inventory/Inquire";
import APIStock from "@/api/PSI/Inventory/inventoryDetail";

import dateUtil from "@/components/js/date";

var Enumerable = require("linq");

export default {
  name: "inventoryProductOutbound",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      ProductOutboundDialogVisible: false,
      OutboundLoading: false,
      selectProductDialogVisible: false,
      OutboundInfoDialogVisible: false,
      productLoading: false,
      // 列表筛选条件
      searchForm: {
        EntityID: "",
        ProductName: "",
        DateTime: "",
        OutType: "", //产品出库类型（10:领料出库、20:报损出库、30：其他出库）
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      OutType: [
        "配送出库",
        "调拨出库",
        "领料出库",
        "报损出库",
        "销售出库",
        "其他出库",
        "加盟商采购出库",
      ],
      newOutType: [
        { ID: "10", Name: "领料出库" },
        { ID: "20", Name: "报损出库" },
        { ID: "30", Name: "其他出库" },
      ],
      data_list: [],
      OutboundFrom: {
        EntityID: "",
        EntityName: "",
        OutDate: "",
        default_time: dateUtil.formatDate.format(new Date(), "hh:mm:ss"),
        OutType: "",
        Remark: "",
        Detail: [],
      },

      OutboundFromRules: {
        EntityID: [
          {
            required: true,
            message: "请选择出库仓库/门店",
            trigger: ["blur", "change"],
          },
        ],
        OutDate: [
          {
            required: true,
            message: "请选择出库日期",
            trigger: ["blur", "change"],
          },
        ],
        OutType: [
          {
            required: true,
            message: "请选择出库类型",
            trigger: ["blur", "change"],
          },
        ],
        Quantity: [{ required: true, trigger: ["blur", "change"] }],
        ProductID: [{ required: true, trigger: ["blur", "change"] }],
        UnitID: [{ required: true, trigger: ["blur", "change"] }],
      },
      EntityList: [], //仓库列表
      removeDisabled: true,
      ProductList: [],
      ProductListTotal: 0,
      productPageNum: 1,
      searchProductName: "",
      ProductPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      multipleProducts: [],
      selectProductList: [],

      OutboundInfo: "", //详情
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  搜索列表数据  */
    searchDataList() {
      let that = this;
      that.paginations.page = 1;
      that.get_list_ProductInventoryOutbound_netWork();
    },
    /** 切换分页   */
    ProductInventoryOutboundCurrentChange(page) {
      let that = this;
      that.paginations.page = page;
      that.get_list_ProductInventoryOutbound_netWork();
    },
    /**  格式化出库类型  */
    OutTypeFormatter(row) {
      switch (row.OutType) {
        case "10":
          return "领料出库";
          break;

        case "20":
          return "报损出库";
          break;

        case "30":
          return "其他出库";
          break;
      }
    },

    /**   新增出库 */
    addOutbound() {
      let that = this;
      that.OutboundFrom = {
        EntityID: "",
        EntityName: "",
        OutDate: dateUtil.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"),
        default_time: dateUtil.formatDate.format(new Date(), "hh:mm:ss"),
        OutType: "",
        Remark: "",
        Detail: [],
      };
      if (that.EntityList.length == 1) {
        let entityItem = that.EntityList[0];
        that.OutboundFrom.EntityID = entityItem.ID;
        that.OutboundFrom.EntityName = entityItem.EntityName;
      }
      that.ProductOutboundDialogVisible = true;
    },
    /** 选择仓库   */
    handleSelectEntity(row) {
      let that = this;
      that.OutboundFrom.EntityID = row.ID;
      that.OutboundFrom.Detail = [];
    },

    /**  新增产品  */
    addProducts() {
      let that = this;
      that.$refs["ProductOutboundFromRef"].validateField(
        "EntityID",
        (valid) => {
          if (valid != "请选择出库仓库") {
            that.OutboundFrom.Detail.push({
              ProductID: "", // ID
              ProductName: "", // 名称
              Alias: "", // 别名
              Specification: "", // 规格
              UnitID: "", // 单位ID
              miniUnitID: "", // 最小单位ID
              UnitName: "", // 单位名称
              Units: [], // 单位集合
              Quantity: "", //  出库数量
              StockQuantity: "", //  当前库存数量
            });
          }
        }
      );
    },
    /**  选择将要删除的产品  */
    removeHandleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    /**  删除所选产品  */
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (var i = 0; i < that.OutboundFrom.Detail.length; i++) {
          that.multipleProducts.forEach(function (item) {
            if (that.OutboundFrom.Detail[i] == item) {
              that.OutboundFrom.Detail.splice(i, 1);
            }
          });
        }
      }
    },

    /**  下拉选择产品  */
    handleSelectProduct(item_ID, row) {
      var that = this;
      row.Quantity = "";
      let item = Enumerable.from(that.ProductList).firstOrDefault((i) => {
        return item_ID == i.ID;
      }, {});
      row.ProductID = item.ID;
      row.ProductName = item.ProductName;
      row.Units = item.Unit;
      row.Specification = item.Specification;
      row.StockQuantity = item.Quantity;

      let defaultUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsDefautSendReceive;
      }, -1);
      let miniUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsMinimumUnit;
      }, -1);
      if (defaultUnit != -1) {
        row.UnitID = defaultUnit.UnitID;
        row.UnitName = defaultUnit.UnitName;
        row.miniAmount = defaultUnit.Amount;
      }
      if (miniUnit != -1) {
        row.miniUnitID = miniUnit.UnitID;
        row.miniUnitName = miniUnit.UnitName;
      }
    },

    /** 获取焦点   */
    selectFocus() {
      let that = this;
      that.ProductList = [];
      that.getStockListNetwork("");
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.ProductList = [];
      that.getStockListNetwork(query);
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.ProductListTotal > that.ProductList.length) {
        that.productPageNum++;
        that.getStockListNetwork();
      }
    },
    /**   选择单位 */
    handleSelectProductUnit(val, row) {
      let that = this;
      row.Quantity = "";
      row.UnitID = val.UnitID;
      row.UnitName = val.UnitName;
      row.miniAmount = val.Amount;
    },

    /**  修改出库数量  */
    changeOutboundQuantity(row) {
      let that = this;
      row.Quantity = Math.floor(row.Quantity);
      let index_ = that.OutboundFrom.Detail.indexOf(row);
      // 去除当前项 已申请的数量 最小单位总和
      let totoMiniQuantity = Enumerable.from(that.OutboundFrom.Detail)
        .where((i, index) => {
          return i.ProductID == row.ProductID && index != index_;
        })
        .sum((i) => Number(i.Quantity) * Number(i.miniAmount));
      let tempQuantity = row.Quantity * row.miniAmount;

      if (
        parseInt(tempQuantity) + parseInt(totoMiniQuantity) >
        row.StockQuantity
      ) {
        row.Quantity = Math.floor(
          (parseInt(row.StockQuantity) - parseInt(totoMiniQuantity)) /
            row.miniAmount
        );

        that.$message.error({
          message: "实物库存大于0才允许出库，并且出库数量不能超过实物库存",
          duration: 2000,
        });
        return;
      }

      // if (row.Quantity > row.StockQuantity) {
      //   row.Quantity = row.StockQuantity
      //   that.$message.error({
      //     message:"库存不足",
      //     duration:2000
      //   })
      //   return;
      // }
    },

    /**  保存产品出库信息  */
    saveProductOutboundLoadingClick() {
      let that = this;
      if (that.OutboundFrom.Detail.length == 0) {
        that.$message.error({
          message: "请选择出库产品",
          duration: 2000,
        });
        return;
      }
      that.$refs["ProductOutboundFromRef"].validate((valid) => {
        if (valid) {
          that.get_create_ProductInventoryOutbound_netWork();
        }
      });
    },
    /** 关闭添加弹窗回调信息   */
    closeAddProductOutbound() {
      let that = this;
      that.$refs["ProductOutboundFromRef"].clearValidate();
      if (that.$refs.multipleTable) {
        that.$refs.multipleTable.clearSelection();
      }
    },

    /**  出库详情  */
    showOutboundInfo(row) {
      let that = this;
      let params = {
        ID: row.ID,
        InventoryType: row.InventoryType,
      };
      that.get_info_ProductInventoryOutbound_netWork(params);
    },

    /** ++++++++++++++ ============================================= +++++++++++++ */

    /**  仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /** 查询 产品 库存列表 列表  */
    getStockListNetwork: function (searchProductName) {
      var that = this;
      var params = {
        PageNum: that.productPageNum,
        ProductName: searchProductName,
        EntityID: that.OutboundFrom.EntityID,
      };
      APIStock.get_stock_list_entityProductList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProductList.push.apply(that.ProductList, res.List);
            that.ProductListTotal = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**  8.1.产品出库列表   */
    get_list_ProductInventoryOutbound_netWork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        // ID: that.searchForm.ID,
        EntityID: that.searchForm.EntityID,
        ProductName: that.searchForm.ProductName,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
        InventoryType: that.searchForm.OutType,
      };
      API.getProductInventoryOutbound_list(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.data_list = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  8.2.产品出库详情   */
    get_info_ProductInventoryOutbound_netWork: function (params) {
      var that = this;
      that.loading = true;
      // var params = {
      //   ID: ID
      // };
      API.getProductInventoryOutbound_info(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.OutboundInfoDialogVisible = true;
            that.OutboundInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  8.3.产品出库   */
    get_create_ProductInventoryOutbound_netWork: function () {
      var that = this;
      that.loading = true;
      var Products = Enumerable.from(that.OutboundFrom.Detail)
        .select((i) => {
          return {
            ProductID: i.ProductID,
            UnitID: i.UnitID,
            Quantity: i.Quantity,
            MinimumUnitID: i.miniUnitID,
            MinimumUnitQuantity:
              Number(i.miniAmount || 0) * Number(i.Quantity || 0),
          };
        })
        .toArray();
      var params = {
        EntityID: that.OutboundFrom.EntityID,
        OutDate: that.OutboundFrom.OutDate,
        OutType: that.OutboundFrom.OutType,
        Remark: that.OutboundFrom.Remark,
        Detail: Products,
      };
      API.getProductInventoryOutbound_create(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "操作成功",
              duration: 2000,
            });
            (that.ProductOutboundDialogVisible = false), that.searchDataList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    // var date = new Date(),
    // y = date.getFullYear(),
    // m = date.getMonth();
    // that.searchForm.DateTime = [dateUtil.formatDate.format(new Date(y, m, 1),"YYYY-MM-DD"),dateUtil.formatDate.format(new Date(),"YYYY-MM-DD")];
    that.OutboundFrom.OutDate = dateUtil.formatDate.format(
      new Date(),
      "YYYY-MM-DD"
    );
    that.searchDataList();
    that.getStorageEntityNetwork();
  },
};
</script>

<style lang="scss">
.ProductInventoryOutbound {
  .ProductOutboundDialogClass {
    .ProductOutboundDialogInfoFrom {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
      .el-input__inner {
        padding-right: 0;
      }
    }
  }

  .IsLockProduct_list_back {
    background-color: #edf2fc;
    cursor: not-allowed;
  }
  .IsLockProduct_list_back:hover > td {
    background-color: #edf2fc !important;
  }
}

.ProductInventoryOutbound_custom_popper_class {
  .el-select-dropdown__item {
    line-height: normal;
    height: auto;
  }
}
</style>
